import React from 'react';
import { Heading, Paragraph, Button, Flex, Box, useThemeUI } from 'theme-ui';
import styled from '@emotion/styled/macro';

import CalendarIcon from 'components/common/Icons/CalendarIcon';
import ClockIcon from 'components/common/Icons/ClockIcon';
import PhoneIcon from 'components/common/Icons/PhoneIcon';

import { openingHours, appointmentsAvailable } from 'lib/appointments';
import PhoneNumber from 'components/common/PhoneNumber';
import ShowMoreText from '../common/ShowMoreText';

const Link = styled.a`
  :visited {
    color: inherit;
  }
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  text-decoration: none;
`;

export const CentreTile = ({
  centre,
  emergencyAppointmentMode,
  setCentre,
  specialistSearchMode,
}) => {
  const hours = openingHours(centre);
  const appointments = appointmentsAvailable(centre);
  const { theme } = useThemeUI();

  return (
    <Flex
      sx={{
        marginTop: '16px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: 'white',
        padding: '16px',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Heading as="h3" variant="styles.h3" color="primary">
          {centre?.details?.pageLink ? (
            <Link target="_blank" href={centre?.details?.pageLink}>
              {centre.title}
            </Link>
          ) : (
            centre.title
          )}
        </Heading>
        <Paragraph
          sx={{
            fontSize: 1,
            fontWeight: 'bold',
          }}
        >
          {parseInt(centre?.distance, 10)}km
        </Paragraph>
      </Flex>
      {centre?.details?.address && (
        <Paragraph
          sx={{
            fontSize: 1,
            fontWeight: 'bold',
            marginTop: '16px',
          }}
        >
          {centre?.details?.address}
        </Paragraph>
      )}
      {centre?.practiceID && (
        <Box sx={{ width: '100%' }}>
          {hours && (
            <Flex
              sx={{
                marginTop: '18px',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <ClockIcon />
              <Paragraph
                sx={{
                  fontSize: 1,
                  marginLeft: '12px',
                }}
              >
                {hours}
              </Paragraph>
            </Flex>
          )}
          {(appointments || centre?.specialistReasons?.length) && (
            <Flex
              sx={{
                marginTop: '18px',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '100%',
                position: 'relative',
              }}
            >
              <CalendarIcon />
              <ShowMoreText
                numberOfLines={4}
                styles={{
                  fontSize: 1,
                  fontStyle: 'italic',
                  marginLeft: '12px',
                }}
              >
                {specialistSearchMode
                  ? centre?.specialistReasons?.join('\n')
                  : appointments}
              </ShowMoreText>
            </Flex>
          )}

          {centre?.details?.phone && (
            <Flex
              sx={{
                marginTop: '18px',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <PhoneIcon />

              <PhoneNumber
                fontSize={1}
                ml="12px"
                color={theme.colors.text}
                phone={centre.details.phone}
              />
            </Flex>
          )}
          <Flex
            sx={{
              width: '100%',
              marginTop: '16px',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {emergencyAppointmentMode &&
            !centre.availableAppointmentsToday &&
            !centre.availableAppointmentsTomorrow ? (
              <Button
                sx={{
                  width: 132,
                  '&:focus': {
                    width: 'auto',
                  },
                }}
                variant="secondary"
              >
                <PhoneNumber
                  lineHeight="inherit"
                  fontSize="inherit"
                  color="white"
                  phone={centre.details.phone}
                  textAlign="center"
                >
                  Call now
                </PhoneNumber>
              </Button>
            ) : (
              <Button variant="primary" onClick={() => setCentre(centre)}>
                Book now
              </Button>
            )}
          </Flex>
        </Box>
      )}
      {!centre?.practiceID && (
        <Paragraph
          sx={{
            fontSize: 1,
            marginTop: '16px',
          }}
        >
          This centre is opening soon
        </Paragraph>
      )}
    </Flex>
  );
};

export const CentrePopup = ({ centre, setCentre }) => {
  const hours = openingHours(centre);

  return (
    <>
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Heading as="h3" variant="styles.h3" color="primary">
          {centre.title}
        </Heading>
        <Paragraph
          sx={{
            fontSize: 1,
            fontWeight: 'bold',
          }}
        >
          {parseInt(centre?.distance, 10)}km
        </Paragraph>
      </Flex>
      {centre?.details?.address ? (
        <Paragraph
          sx={{
            fontSize: 1,
            fontWeight: 'bold',
            marginTop: '16px',
          }}
        >
          {centre?.details?.address}
        </Paragraph>
      ) : null}
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '16px',
        }}
      >
        {centre?.practiceID ? (
          hours && (
            <Flex
              sx={{
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <ClockIcon />
              <Paragraph
                sx={{
                  fontSize: 1,
                  marginLeft: '12px',
                }}
              >
                {hours}
              </Paragraph>
            </Flex>
          )
        ) : (
          <Paragraph
            sx={{
              fontSize: 1,
            }}
          >
            This centre is opening soon
          </Paragraph>
        )}
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button variant="primary" onClick={() => setCentre(centre)}>
            Book now
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
